import React, { Component, useState, useContext, useEffect } from "react";
import { ApiContext } from '../../context/apiContext';
import './settings.scss';
import {
  Page,
  HorizontalGrid,
  Box,
  VerticalStack,
  Text,
  Card,
  TextField,
  Divider,
  Checkbox,
  ButtonGroup,
  Button,
  Select,
  IndexFiltersMode,
  HorizontalStack
} from '@shopify/polaris';
const syncTimes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

export default function Settings () {

  const {
    shopifySite,
    fetchShopifySite,
    fetchSettings, updateSettings,
    settings, setSettings
  } = useContext(ApiContext)

  fetchShopifySite()
  useEffect(() => {
    console.log(location)
    fetchSettings()
  }, [])

  /* Order Functions */

  function handleOrderSyncToggle() {
    return setSettings( prevState => {
      const updatedToggle = !prevState.shopifyOrderSync
      updateSettings('shopifyOrderSync', updatedToggle)
      return { ...prevState, shopifyOrderSync: updatedToggle }
    }
  )}

  function handleOrderChange(val, input) {
    return setSettings( prevState => {
      updateSettings(input, val)
      return { ...prevState, [input]: val }
    })
  }

  /* Inventory Functions */

  function handleInvSyncToggle() {
    return setSettings( prevState => {
      const updatedToggle = !prevState.shopifyInventorySync
      updateSettings('shopifyInventorySync', updatedToggle)
      return {...prevState, shopifyInventorySync: updatedToggle}}
    )
  }

  function handleInvChange(val, input) {
    return setInv( prevState => { return { ...prevState, [input]: val }})
  }

  function updateNewWarehouseCode (val) {
    setSettings( prevInv => { return { ...prevInv, newWarehouseCode: val } })
  }

  function addNewWarehouse () {
    if (settings.shopifyLocationMap.indexOf(settings.newWarehouseCode) == -1) {
      return setSettings( prevState => {
        const oldArr = settings.shopifyLocationMap
        oldArr.push(
          {
            d365WarehouseCode: prevState.newWarehouseCode,
            shopifyLocationId: ''
          }
        )
        updateSettings('shopifyLocationMap', oldArr)
        return {
          ...prevState,
          shopifyLocationMap: oldArr,
          newWarehouseCode: ''
        }
      })
    } else { return alert('Warehouse Already Exists') }
  }

  function removeWarehouse(i) {
    return setSettings( prevState => {
      const oldArr = prevState.shopifyLocationMap
      oldArr.splice(i, 1)
      updateSettings('shopifyLocationMap', oldArr)
      return {
        ...prevState,
        shopifyLocationMap: oldArr
      }
    })
  }

  function updateShopifyLocationId(val, i) {
    setSettings( prevState => {
      let oldArr = prevState.shopifyLocationMap
      let updatedItem = {
        d365WarehouseCode: oldArr[i].d365WarehouseCode,
        shopifyLocationId: val
      }
      oldArr[i] = updatedItem

      return {
        ...prevState,
        shopifyLocationMap: oldArr
      }
    })
  }

  function handleInvSyncInterval(val) {
    return setSettings(prevState => {
      let arr = JSON.parse(val)
      let newObj = prevState.shopifyInventorySyncTimes;
        for (var i = 0; i < 24; i++) {
          if(arr.indexOf(i) != -1){
            newObj[i] = true
          } else{
            newObj[i] = false
          }
        }
      updateSettings('shopifyInventorySyncTimes', newObj)
      return{
        ...prevState,
        invSyncInterval: val,
        shopifyInventorySyncTimes: newObj
      }
    })
  }

  function handleAdvacedModeToggle() { return setSettings(prevState => { return {...prevState, advancedModeActive: !prevState.advancedModeActive} })}

  function updateSyncTimes(data) { // after the first run this runs double
    setSettings (prevState => {
      let updatedObj = prevState.shopifyInventorySyncTimes
      updatedObj[data] = !updatedObj[data] // toggle the current value
      console.log(updatedObj)
      // console.log(data)
      return {
        ...prevState,
        shopifyInventorySyncTimes: updatedObj
      }
    })
  }

  return(
  <div className="settings-page">
        <Page>
          <Text as="h3" variant="headingXl">
            Settings
          </Text>
          &nbsp;
          <Divider />
          &nbsp;
          <VerticalStack gap={{ xs: "800", sm: "400" }}>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
              &nbsp;
              <Text as="h3" variant="headingLg">
                Order Sync
              </Text>
              </Box>
              <Card roundedAbove="sm">
                <Checkbox
                    label="Enable Order Sync"
                    checked={settings.shopifyOrderSync}
                    onChange={handleOrderSyncToggle}
                  />
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Contact Email
                  </Text>
                  <Text as="p" variant="bodyMd">
                    This email will receive automated alerts when order imports fail
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  <TextField
                    label="Contact Email"
                    value={settings.contactEmail}
                    onChange={(val) => {handleOrderChange(val, 'contactEmail')}}
                    onBlur={() => updateSettings('contactEmail', settings.contactEmail )}
                  />
                </VerticalStack>
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Data Area Id
                  </Text>
                  <Text as="p" variant="bodyMd">
                    This is the d365 data area id that is used to assign an order
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  <TextField
                    label="D365 Data Area Id"
                    value={settings.d365DataAreaId}
                    onChange={(val) => {handleOrderChange(val, 'd365DataAreaId')}}
                    onBlur={() => updateSettings('d365DataAreaId', settings.d365DataAreaId )}
                  />
                </VerticalStack>
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Order Sync Exclusion List
                  </Text>
                  <Text as="p" variant="bodyMd">
                    A comma separated list of tags that will be used to exclude items from the order sync. Ex: Suspicious, Fraud etc...
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  <TextField
                    label="List of Tags"
                    value={settings.orderSyncExclusionTagList}
                    onChange={(val) => {handleOrderChange(val, 'orderSyncExclusionTagList')}}
                    onBlur={() => updateSettings('orderSyncExclusionTagList', settings.orderSyncExclusionTagList )}
                  />
                </VerticalStack>
              </Card>
            </HorizontalGrid>
            &nbsp;
            &nbsp;
            <Divider />
            &nbsp;
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                &nbsp;
              <Text as="h3" variant="headingLg">
                Inventory Sync
              </Text>
              </Box>
              <Card roundedAbove="sm">
                <Checkbox
                    label="Enable Inventory Sync"
                    checked={settings.shopifyInventorySync}
                    onChange={handleInvSyncToggle}
                  />
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    D365 Warehouse Link
                  </Text>
                  <Text as="p" variant="bodyMd">
                    The mapping between a d365 warehouse code and the shopify location to update inventory
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                {'D365 Warehouse Mapping'}
                {settings.shopifyLocationMap && settings.shopifyLocationMap.map((data, i) => {
                  return (
                    <ButtonGroup>
                        <TextField
                          placeholder="enter code"
                          value={data.d365WarehouseCode}
                          disabled
                        />
                        <TextField
                          placeholder="enter shopify location Id"
                          value={data.shopifyLocationId}
                          onChange ={(val) => {updateShopifyLocationId(val, i)}}
                          onBlur={() => { updateSettings('shopifyLocationMap', settings.shopifyLocationMap) }}
                        />
                       <Button secondary onClick={()=> removeWarehouse(i)}>{'x'}</Button>
                     </ButtonGroup>
                  )
                })}
                <ButtonGroup>
                    <TextField
                      placeholder="enter code"
                      value={settings.newWarehouseCode}
                      onChange={updateNewWarehouseCode}
                    />
                   <Button primary onClick={addNewWarehouse}>{'Add Warehouse'}</Button>
                 </ButtonGroup>


                </VerticalStack>
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                  as="section"
                  paddingInlineStart={{ xs: 400, sm: 0 }}
                  paddingInlineEnd={{ xs: 400, sm: 0 }}
                >
                  <VerticalStack gap="400">
                    <Text as="h3" variant="headingMd">
                      D365 Inventory Key
                    </Text>
                    <Text as="p" variant="bodyMd">
                      This is the exact value from D365 that we use to update inventory to shopify
                    </Text>
                  </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  <Select
                    label="d365 Inventory Key"
                    options={[
                      {value: 'AvailableOnHandQuantity', label: 'AvailableOnHandQuantity'},
                      {value: 'TotalAvailableQuantity', label: 'TotalAvailableQuantity'},
                      {value: 'AvailableOnHandQuantity - OnOrderQuantity', label: 'AvailableOnHandQuantity - OnOrderQuantity'},
                      {value: 'AvailableOnHandQuantity – OnOrderQuantity - ReservedOrderedQuantity', label: 'AvailableOnHandQuantity – OnOrderQuantity - ReservedOrderedQuantity'},
                      {value: 'ExperimentalCustomOnHandQuantityValue', label: 'ExperimentalCustomOnHandQuantityValue'}
                    ]}
                    value={settings.d365InventoryKey}
                    onChange={(val) => {handleOrderChange(val, 'd365InventoryKey')}}

                  />
                </VerticalStack>
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Inventory Sync Exclusion List
                  </Text>
                  <Text as="p" variant="bodyMd">
                    A comma separated list of tags that will be used to exclude items from the inventory sync. Ex: Pre-Order,Sale,No-Sync
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  <TextField
                    label="List of Tags"
                    value={settings.inventorySyncExclusionTagList}
                    onChange={(val) => {handleOrderChange(val, 'inventorySyncExclusionTagList')}}
                    onBlur={() => updateSettings('inventorySyncExclusionTagList', settings.inventorySyncExclusionTagList )}
                  />
                </VerticalStack>
              </Card>
            </HorizontalGrid>
            <HorizontalGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
              <Box
                as="section"
                paddingInlineStart={{ xs: 400, sm: 0 }}
                paddingInlineEnd={{ xs: 400, sm: 0 }}
              >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Inventory Sync Timing Select
                  </Text>

                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="500">
                    { settings.advancedModeActive ?
                        <Select
                        label="Inventory Sync Timings"
                        options={[
                          {value: '[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]', label: 'Every Hour'},
                          {value: '[0,2,4,6,8,10,12,14,16,18,20,22]', label: 'Every 2 Hour'},
                          {value: '[0,4,8,12,16,20]', label: 'Every 4 Hour'},
                          {value: '[0,6,12,18]', label: 'Every 6 Hour'},
                        ]}
                        value={settings.invSyncInterval}
                        onChange={handleInvSyncInterval}
                        disabled
                      />
                    :
                        <Select
                        label="Inventory Sync Timings"
                        options={[
                          {value: '[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]', label: 'Every Hour'},
                          {value: '[0,2,4,6,8,10,12,14,16,18,20,22]', label: 'Every 2 Hour'},
                          {value: '[0,4,8,12,16,20]', label: 'Every 4 Hour'},
                          {value: '[0,6,12,18]', label: 'Every 6 Hour'},
                        ]}
                        value={settings.invSyncInterval}
                        onChange={handleInvSyncInterval}
                        />

                    }
                </VerticalStack>
                  {/*
                    <Checkbox
                      label="Advanced"
                      checked={settings.advancedModeActive}
                      onChange={handleAdvacedModeToggle}
                    />
                  */}
              </Card>
              { settings.advancedModeActive ?
                <>
                  <Box
                  as="section"
                  paddingInlineStart={{ xs: 400, sm: 0 }}
                  paddingInlineEnd={{ xs: 400, sm: 0 }}
                  >
                <VerticalStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Inventory Sync Timing
                  </Text>
                  <Text as="p" variant="bodyMd">
                    By default the api will sync inventory every hour of the day. The time is UTC time
                  </Text>
                  <Text as="p" variant="bodyMd">
                    PST: Pacific Standard Time is 7 hours behind UTC universal time.
                  </Text>
                  <Text as="p" variant="bodyMd">
                    CST: Central Standard Time is 6 hours behind UTC universal time.
                  </Text>
                  <Text as="p" variant="bodyMd">
                    EST: Eastern Standard Time is 5 hours behind UTC universal time.
                  </Text>
                </VerticalStack>
              </Box>
              <Card roundedAbove="sm">
                <VerticalStack gap="400">
                  {syncTimes.map((data,index) => {
                    let string = data.toString()+':00 am';
                    if(data>12){
                      let pm = data-12;
                      string = pm.toString()+':00 pm';
                    }
                    return (
                      <Checkbox
                        label={string}
                        checked={settings.shopifyInventorySyncTimes[data]}
                        onChange={() =>updateSyncTimes(data)}
                      />
                    );
                  })}
                </VerticalStack>
              </Card>
                </>
              :
                null
              }
            </HorizontalGrid>
          </VerticalStack>
        </Page>
      </div>
  )
}
